@import "variables";

/******* Base styles *******/

html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: $font-family;
    font-size: 14px;
    line-height: 1.5;
}

* {
    margin: 0;
    padding: 0;
}

a {
    outline: none;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.w-25 {
    width: 25% !important;
}

.w-45 {
    width: 45% !important;
}

.w-50 {
    width: 50% !important;
}

.w-100 {
    width: 100% !important;
}

.mw-100 {
    max-width: 100%;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.lh {
    line-height: 27px;
}

.p-relative {
    position: relative;
}

.d-flex-middle {
    display: flex !important;
    align-items: center;
}

.max-width-inherit {
    max-width: inherit !important;
}

.theme-container {
    max-width: 100%;
    margin: 0 auto;
}

.light-block {
    background: rgba(0, 0, 0, 0.03) !important;
    box-shadow: none !important;
}

.no-shadow {
    box-shadow: none !important;
}

.transition {
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    transition: 0.3s;
}

.text-truncate {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden !important;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.divider {
    border-top: 1px solid rgba(204, 204, 204, 0.6);
}

.m-0-auto {
    margin: 0 auto;
    display: block;
}

.m-1-auto {
    margin: 0px 0px 0px 128px;
}

.vertAlignMidCenter {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.mat-icon-button .mat-icon.mat-icon-xs,
.mat-icon.mat-icon-xs {
    font-size: 14px;
    line-height: 14px;
    height: 14px;
    width: 14px;
    vertical-align: middle;
}

.mat-icon-button .mat-icon.mat-icon-sm,
.mat-icon.mat-icon-sm,
.mat-nav-list .mat-list-item .mat-list-icon.mat-icon-sm {
    font-size: 18px;
    line-height: 18px;
    height: 18px;
    width: 18px;
    vertical-align: middle;
}

.mat-icon-button .mat-icon.mat-icon-lg,
.mat-icon.mat-icon-lg {
    font-size: 36px;
    line-height: 24px;
    height: 18px;
    width: 18px;
    vertical-align: middle;
}

.mat-icon-button .mat-icon.mat-icon-xlg,
.mat-icon.mat-icon-xlg {
    font-size: 48px;
    line-height: 48px;
    height: 48px;
    width: 48px;
    vertical-align: middle;
}

.mat-icon.caret {
    width: 14px;
}

.mat-button.flex-row-button .mat-button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mat-button-wrapper {
    pointer-events: none;
}

.mat-list.reviews .mat-list-item .mat-list-item-content {
    align-items: start;
}

.mat-step-text-label {
    text-transform: uppercase;
}

.mat-tab-label-content {
    font-size: 16px;
}

.mat-row,
.mat-header-row {
    border-bottom-color: rgba(0, 0, 0, 0.12);
}

.filter-sidenav .mat-drawer-inner-container {
    height: auto;
    padding: 2px;
    box-sizing: border-box;

    .mat-expansion-panel {
        border-radius: 0 !important;
    }
}

.filter-sidenav-100 .mat-drawer-inner-container {
    height: auto;
    margin-top: 2rem;
    padding: 1rem;
    box-sizing: border-box;

    .mat-expansion-panel {
        border-radius: 0 !important;
    }
}

.app-dropdown .mat-menu-item {
    height: 38px;
    line-height: 41px;
    font-size: 14px;
    color: #6a4e3e;
}

.account-icon {
    margin-top: -2px;
    margin-right: 2px;
}

.app-dropdown.account {
    width: 13.5rem;
    border-radius: 11px;

    .mat-menu-content {
        padding: 0;

        .user-info {
            padding: 11px 15px;

            p {
                width: 13rem;
                text-overflow: ellipsis;
                font-size: 16px;
                overflow: hidden;
                font-weight: bold;
                color: #6a4e3e;
                letter-spacing: 1px;
                white-space: nowrap;
            }
        }
    }
}

.categories-dropdown.mat-menu-panel {
    max-height: 350px;
}

.categories-dropdown .mat-menu-item {
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    color: #6a4e3e;
    // font-family: "Montserrat", cursive !important;
    font-family: "uni-neue-light", cursive !important;
}

.sub-category button {
    padding-left: 30px;
}

.sub-category .sub-category button {
    padding-left: 50px;
}

.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: 10px !important;
}

.p-2 {
    padding: 20px !important;
}

.p-3 {
    padding: 30px !important;
}

.py-1 {
    padding: 10px 0 !important;
}

.py-2 {
    padding: 20px 0 !important;
}

.py-3 {
    padding: 30px 0 !important;
}

.py-4 {
    padding: 40px 0 !important;
}

.py-5 {
    padding: 50px 0 !important;
}

.px-1 {
    padding: 0 10px !important;
}

.px-2 {
    padding: 0 20px !important;
}

.px-3 {
    padding: 0 30px !important;
}

.px-4 {
    padding: 0 40px !important;
}

.px-5 {
    padding: 0 50px !important;
}

.pl-1 {
    padding-left: 10px;
}

.pl-2 {
    padding-left: 20px;
}

.pl-3 {
    padding-left: 30px;
}

.pl-5 {
    padding-left: 50px;
}

.pr-1 {
    padding-right: 10px;
}

.pr-2 {
    padding-right: 20px;
}

.pt-1 {
    padding-top: 10px;
}

.pb-2 {
    padding-bottom: 20px;
}

.m-0 {
    margin: 0 !important;
}

.m-5rem {
    margin: 5rem !important;
}

.m-05rem {
    margin: 0 5rem !important;
}

.m-50rem {
    margin: 5rem 0rem 0rem 5rem !important;
}

.mt-1 {
    margin-top: 10px !important;
}

.mt-2 {
    margin-top: 20px !important;
}

.mt-3 {
    margin-top: 30px !important;
}

.mt-4 {
    margin-top: 40px !important;
}

.mt-5 {
    margin-top: 50px !important;
}

.mt-6 {
    margin-top: 60px !important;
}

.mt-7 {
    margin-top: 70px !important;
}

.mt-8 {
    margin-top: 80px !important;
}

.mb-1 {
    margin-bottom: 10px !important;
}

.mb-2 {
    margin-bottom: 20px !important;
}

.mb-3 {
    margin-bottom: 30px !important;
}

.mb-4 {
    margin-bottom: 40px !important;
}

.mb-5 {
    margin-bottom: 50px !important;
}

.mb-7 {
    margin-bottom: 70px !important;
}

.mb-10 {
    margin-bottom: 100px !important;
}

.mr-1 {
    margin-right: 10px !important;
}

.mr-2 {
    margin-right: 15px !important;
}

.mr-3 {
    margin-right: 20px !important;
}

.mr-4 {
    margin-right: 25px !important;
}

.ml-1 {
    margin-left: 10px !important;
}

.ml-2 {
    margin-left: 20px !important;
}

.ml-3 {
    margin-left: 30px !important;
}

.ml-5 {
    margin-left: 50px !important;
}

.ml-10 {
    margin-left: 100px !important;
}

.d-block {
    display: block;
}

.d-block-important {
    display: block !important;
}

.d-inline-block {
    display: inline-block;
}

.info-bar {
    margin-top: 30px;

    .mat-card {
        height: 100px;

        .content {
            margin-left: 10px;

            p {
                font-weight: 500;
            }

            span {
                font-size: 13px;
            }
        }
    }
}

.social-icon {
    width: 48px;
    height: 48px;
    cursor: pointer;
    fill: #8f8f8f;
    outline: none;
}

.icons-p {
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 0px 5px;
}

.icons-whatsapp {
    width: 24px;
    height: 24px;
    line-height: 24px;
    // margin: 0px 5px;
}

.icons-p-wharehouse {
    width: 34px;
    height: 40px;
    line-height: 30px;
    margin: 0px 12px;
}

/******* Toolbar *******/

.top-toolbar.mat-toolbar-row {
    height: 25px;
    font-size: 13px;

    .sidenav-toggle {
        height: 20px;
        line-height: 20px;
        min-width: 24px;
        padding: 0;
        border-radius: 0;
        box-shadow: none;
    }

    .top-menu {
        margin-right: -16px;
        text-transform: uppercase;

        a {
            text-decoration: none;
            font-weight: 400;
            font-size: 13px;
            line-height: 31px;
            padding: 0 10px;
            min-width: 70px;
            border-radius: 0;
        }

        a.mobile-menu-icon {
            min-width: 30px;
        }

        .mat-icon.more {
            height: 28px;
        }
    }
}

.flag-menu-title {
    vertical-align: top !important;
    margin-left: 3px;
}

.top-menu-dropdown .mat-menu-item img {
    vertical-align: middle;
    margin-right: 8px;
}

.cart-dropdown.mat-menu-panel {
    padding: 6px 14px;
    border-radius: 12px;
    font-family: "uni-neue-light", cursive !important;
    color: #6a4e3e;

    // font-size: 13px;
    // max-height: 350px;
    .mat-list {
        padding: 0;

        .mat-list-item {
            .mat-list-item-content {
                padding: 0;

                img {
                    width: 70px;
                    margin-left: 10px;
                }
            }

            .mat-list-text > * {
                font-size: 14px;
                font-weight: 500;
            }

            .mat-line:nth-child(n + 2) {
                font-size: 13px;
                font-weight: 400;
            }
        }
    }

    .cart-dropdown-footer {
        font-size: 14px;
    }
}

.logo-toolbar.mat-toolbar-row {
    height: 4.7rem;
}

.logo {
    font-size: 28px;
    font-weight: 900;
    text-transform: uppercase;
    text-decoration: none;
    color: inherit;
}

// .search-form {
//     border-radius: 22px;
//     border: 2px solid #424242;
//     overflow: hidden;
//     .categories {
//         height: 40px;
//         width: 180px;
//         border-radius: 0;
//     }
//     input[type="text"] {
//         border: 0;
//         outline: none;
//         padding: 0 34px 0 12px;
//     }
//     .search-btn {
//         margin-left: -20px;
//         background: #fff !important;
//         box-shadow: none !important;
//     }
// }
// .search-dropdown.mat-menu-panel {
//     border-radius: 26px;
//     max-width: 100%;
//     .mat-menu-content {
//         padding: 0;
//     }
// }

.search-form {
    border-radius: 0px;
    overflow: hidden;

    .categories {
        height: 40px;
        width: 180px;
        border-radius: 0;
    }

    input[type="text"] {
        border: 0;
        outline: none;
        padding: 0 34px 0 19px;
        // margin: 1rem;
        width: 310px;
        height: 42px;
        box-shadow: 0px 3px 6px #00000029;
        background: #6a4e3e 0% 0% no-repeat padding-box;
        font-family: "uni-neue-light", cursive !important;
        color: #fff;

        // width: 374px;
        // height: 42px;
        // background: #6A4E3E 0% 0% no-repeat padding-box;
        // box-shadow: 0px 3px 6px #5E5E5E;
        // border-radius: 31px;
        // opacity: 1;
    }

    .search-btn {
        margin-left: -40px;
        background: #6a4e3e !important;
        box-shadow: none !important;
        height: 2.6rem !important;
    }
}

.mat-menu-panel {
    min-height: 42px !important;
}

.search-dropdown.mat-menu-panel {
    border-radius: 26px;
    max-width: 100%;

    .mat-menu-content {
        padding: 0;
    }
}

.top-cart {
    span {
        font-size: 13px;
        font-weight: 400;
        line-height: 1.2;
    }
}

/******* Top navbar (menu) *******/

.top-navbar.mat-toolbar {
    height: 48px;
    padding: 0;
    width: 100%;

    ul {
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            flex-grow: 1;
            text-align: center;

            a {
                display: block;
                height: 100%;
                border-radius: 0;
                text-transform: uppercase;
                padding: 0;
                line-height: 48px;

                &:hover {
                    .subtituloMenu {
                        display: block !important;
                    }
                }

                .mat-button-wrapper {
                    line-height: 18px;

                    .subtituloMenu {
                        display: none;
                        font-size: 15px;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}

.cdk-overlay-container {
    z-index: 999999999999 !important;
}

.cdk-overlay-pane.mega-menu-pane {
    left: 0 !important;
    width: 100%;

    .mat-menu-panel {
        width: 100%;
        max-width: none;
        border-radius: 0;
        opacity: 0.75;

        .mat-menu-content {
            padding: 0;
        }
    }

    .mega-menu-widget {
        position: relative;

        button {
            position: absolute;
            top: 10px;
            left: 10px;
            font-weight: 400;
        }

        .mat-chip-list {
            position: absolute;
            bottom: 10px;
            left: 10px;
        }
    }
}

/******* Carousel *******/

// button.swipe-arrow {
//     position: absolute;
//     top: 50%;
//     margin-top: -22px;
//     z-index: 10;
//     background-image: none;
// }
button.swipe-arrow {
    position: absolute;
    top: 50%;
    margin-top: -22px;
    z-index: 10;
    background-image: none;
}

a.swipe-arrow {
    position: absolute;
    top: 40%;
    // margin-top: -22px;
    z-index: 10;
    background-image: none;
    margin: -1rem;
}

a.swiper-button-prev {
    position: absolute;
    top: 40%;
    // margin-top: -22px;
    right: initial;
    z-index: 10;
    background-image: none;
    margin: -1rem;
}

.swiper-pagination.white .swiper-pagination-bullet {
    background: #fff;
}

.swiper-pagination-bullet-active {
    width: 12px;
    height: 12px;
    vertical-align: -2px;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
    content: none;
}

/* Lançamentos */

.banner-lancamento {
    position: relative;

    .mat-chip-list {
        position: absolute;
        top: 10px;
        right: 10px;

        &.list-view {
            left: 10px;
        }
    }

    .image-link {
        display: block;
        height: 250px;
        cursor: pointer;
    }

    img {
        width: 100%;
        height: 100%;
    }

    .category {
        margin-bottom: 4px;
    }

    .title {
        display: block;
        // color: inherit;
        text-decoration: none;
        // text-transform: uppercase;
        // font-family: "cinzel-decorative-regular", cursive !important;
        margin-top: 15px;
        margin-bottom: 15px;
        // font-weight: 500;
        // font-size: 15px;
        // line-height: 18px;
        text-align: left;
        // font: normal normal normal 20px/26px Cinzel Decorative;
        font: normal normal normal 20px/26px Uni Neue;
        // font-family: "caviar-dreams", cursive !important;
        font-family: "uni-neue-light", cursive !important;
        letter-spacing: 0px;
        color: #6a4e3e;
        text-transform: capitalize;
        opacity: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .prices {
        margin: 12px 0;

        .old-price {
            text-decoration: line-through;
            height: 15px;
        }

        .new-price {
            font-size: 16px;
            font-weight: 500;
        }
    }

    .icons {
        margin-top: 18px;
    }
}

/******* Product *******/

.product-item {
    position: relative;

    .mat-chip-list {
        position: absolute;
        top: 10px;
        right: 10px;

        &.list-view {
            left: 10px;
        }
    }

    .image-link {
        display: block;
        height: 250px;
        cursor: pointer;
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }

    .category {
        margin-bottom: 4px;
    }

    .title {
        display: block;
        color: #6a4e3e;
        // color: inherit;
        text-decoration: none;
        // text-transform: uppercase;
        // font-family: "cinzel-decorative-regular", cursive !important;
        margin-top: 15px;
        margin-bottom: 15px;
        // font-weight: 500;
        // font-size: 15px;
        // line-height: 18px;
        text-align: left;
        // font: normal normal normal 20px/26px Cinzel Decorative;
        font: normal normal normal 20px/26px Uni Neue;
        // font-family: "caviar-dreams", cursive !important;
        font-family: "uni-neue-light", cursive !important;
        letter-spacing: 0px;
        color: #6a4e3e;
        text-transform: capitalize;
        opacity: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .prices {
        margin: 12px 0;

        .old-price {
            text-decoration: line-through;
            height: 15px;
        }

        .new-price {
            font-size: 16px;
            font-weight: 500;
        }
    }

    .icons {
        margin-top: 18px;
    }
}

.old-price {
    text-decoration: line-through;
}

/******* Back to top *******/

.back-to-top {
    position: fixed;
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 999999;
    right: 20px;
    bottom: 50px;
    opacity: 1;
    // color: #fff;
    // background-color: rgba(0, 0, 0, 0.75);
    border-radius: 4px;

    &:hover {
        opacity: 0.9;
    }
}

/******* Div Instagram *****/

#bannerInstagram {
    p {
        font-size: 20px;
        margin-bottom: 10px;
        text-align: center;
        font-weight: bold;
        color: #424242;

        img {
            max-width: 20px;
            vertical-align: baseline;
        }
    }

    h2 {
        text-align: center;
        font-size: 36px;
        margin: 15px auto;
    }

    img {
        width: 109%;
        margin: 0% -4%;
    }
}

/******* Button default *****/

#buttonDefault {
    a.btn {
        letter-spacing: 1px;
        //  font-family: "Montserrat", cursive !important;
        font-family: "uni-neue-light", cursive !important;
        position: relative;
        text-transform: uppercase;
        cursor: pointer;
        text-decoration: none;
        color: #684e3c;
        font-weight: bold;
    }

    // a.btn:before {
    //     content: "";
    //     position: absolute;
    //     width: 100%;
    //     height: 2px;
    //     bottom: -3px;
    //     left: 0;
    //     background-color: #684E3C;
    //     cursor: pointer;
    // }

    // a.btn:before {
    //     content: "";
    //     position: absolute;
    //     width: 100%;
    //     height: 2px;
    //     bottom: -3px;
    //     left: 0;
    //     background-color: #684E3C;
    //     visibility: hidden;
    //     transform: scaleX(0);
    //     transition: all 0.3s ease-in-out;
    // }

    // a.btn:hover:before {
    //     visibility: visible;
    //     transform: scaleX(1);
    // }
}

/******* Responsive *******/

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575px) {
    .top-toolbar.mat-toolbar-row {
        padding: 0;

        .top-menu {
            margin-right: 0;

            a {
                min-width: 56px;
            }
        }

        .sidenav-toggle {
            min-width: 52px;
        }
    }

    .search-form input[type="text"] {
        // width: 160px;
        width: 18rem;
    }

    .search-form .categories {
        width: 140px;
    }

    #bannerInstagram {
        h2 {
            text-align: center;
            font-size: 19px;
            margin: 15px auto;
        }
    }

    button.mat-icon-button {
        line-height: 25px;
        width: 39px;
        height: 25px;
    }

    .top-toolbar.mat-toolbar-row .sidenav-toggle {
        min-width: 65px;
        margin: 40px -5px 0px;
    }

    .cdk-overlay-pane.mega-menu-pane {
        .mat-menu-panel {
            width: 575px;
        }
    }

    .product-item {
        .title {
            justify-content: center;
            font: normal normal normal 25px/36px Uni Neue;
            font-family: "uni-neue-light", cursive !important;
            color: #6a4e3e;
            text-align: center;
            font-weight: lighter;
            letter-spacing: 1.2px;
            font-size: 1.2rem;
        }

        span {
            justify-content: center;
            font: normal normal normal 18px/36px Uni Neue;
            font-family: "uni-neue-light", cursive !important;
            // font-family: "caviar-dreams", cursive !important;

            color: #6a4e3e;
            text-align: center;
            font-weight: inherit;
        }
    }

    #bannersHome {
        .slideBanner img {
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
            display: block;
            height: 8rem;
            padding-top: 4.7rem;
        }
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767px) {
    .top-toolbar.mat-toolbar-row {
        padding: 0;

        .top-menu {
            margin-right: 0;
        }

        .sidenav-toggle {
            min-width: 52px;
        }
    }

    .product-item {
        .title {
            justify-content: center;
            color: #6a4e3e;
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991px) {
    .top-toolbar.mat-toolbar-row {
        padding: 0;

        .top-menu {
            margin-right: 0;
        }

        .sidenav-toggle {
            min-width: 52px;
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

@media (max-width: 959px) {
    .cdk-overlay-connected-position-bounding-box {
        right: 20px !important;
    }

    .search-form {
        border-radius: 15px;
        border: none;
        background-color: #fafafa;
        opacity: 1;
    }
}

@media (max-width: 414px) {
    .cdk-overlay-connected-position-bounding-box {
        width: 420px !important;
    }

    .cdk-overlay-connected-position-bounding-box {
        top: 25px !important;
        left: -35px !important;
    }
}

@media (max-width: 355px) {
    .cdk-overlay-connected-position-bounding-box {
        right: 20px !important;
        left: initial !important;
        width: 280px !important;
    }
}

@media (max-width: 335px) {
    .cdk-overlay-pane {
        right: 0 !important;
    }
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #fff;
}

::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-thumb {
    background: #212121;
    border: 0px none #212121;
    border-radius: 5px;

    &:hover {
        background: #212121;
    }

    &:active {
        background: #212121;
    }
}

::-webkit-scrollbar-thumb:horizontal {
    background: #c8c8c0;
    border-radius: 5px;
}

::-webkit-scrollbar-track {
    border: 0px none transparent;
    border-radius: 0px;

    &:hover {
        background: transparent;
    }

    &:active {
        background: transparent;
    }
}

::-webkit-scrollbar-corner {
    background: transparent;
}

::-webkit-scrollbar {
    display: none;
    /* Para ocultar a barra de rolagem em navegadores baseados em Webkit */
}

/* perfect-scrollbar */

.ps {
    -ms-touch-action: auto;
    touch-action: auto;
    overflow: hidden !important;
    position: relative;
    scrollbar-width: none;
    /* Para ocultar a barra de rolagem em navegadores com suporte */
    -ms-overflow-style: none;
    /* Para ocultar a barra de rolagem em navegadores da Microsoft */
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps {
        overflow: auto !important;
    }
}

.ps__rail-x {
    display: none;
    opacity: 0;
    transition: background-color 0.2s linear, opacity 0.2s linear;
    -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
    height: 7px;
    bottom: 0px;
    position: absolute;
}

.ps__rail-y {
    display: none;
    opacity: 0;
    transition: background-color 0.2s linear, opacity 0.2s linear;
    -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
    width: 7px;
    right: 0px;
    left: auto !important;
    position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
    display: block;
    background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
    opacity: 0.6;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus {
    background-color: #eee;
    opacity: 0.9;
}

.ps__thumb-x {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color 0.2s linear, height 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
    height: 4px;
    bottom: 1px;
    position: absolute;
}

.ps__thumb-y {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color 0.2s linear, width 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
    width: 4px;
    left: 10px;
    position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x {
    background-color: #999;
    height: 7px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y {
    background-color: #999;
    width: 7px;
}

@supports (-ms-overflow-style: none) {
    .ps {
        overflow: auto !important;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps {
        overflow: auto !important;
    }
}

// @media (min-width: 1920px) {
//     .theme-container {
//         min-width: 1350px;
//     }
// }

// @media (min-width: 1380px) {
//     .theme-container {
//         margin: 0;
//         max-width: 1800px;
//     }
// }

.sidenav .mat-drawer-inner-container {
    overflow-x: hidden;
}

.controlesProduto {
    .mat-form-field {
        width: 90%;

        .mat-form-field-wrapper {
            border: 1px solid #969696;
            padding: 0 10px 10px 15px;
            border-radius: 5px;

            .mat-form-field-infix {
                padding: 0;
            }
        }
    }

    .btnSobMedida {
        padding-top: 25px;
    }

    .valorProduto {
        padding-top: 10px;
    }
}

.btnAddCarrinho {
    padding: 5px 0 !important;
    font-size: 16px !important;
    text-transform: uppercase;
    transition: filter 0.2s;
    background: #646464 !important;
    color: #000 !important;

    &:hover {
        background: #0a0a0a !important;
    }
}

.btnAddCarrinho[disabled]:hover {
    background: rgba(0, 0, 0, 0.12) !important;
}

.controlesProduto.qtdeProduto .mat-form-field-subscript-wrapper {
    top: 35px;
    left: 0;
    font-size: 14px;
}

#btnNotFound .mat-icon {
    height: 18px;
    width: 18px;
    font-size: 18px;
    line-height: 15px;
}

.titleSobmedida {
    .mat-card-title {
        font-size: 16px !important;
    }
}

.titleSobmedida,
.titleTotal {
    .mat-card-header-text {
        width: 100%;
    }
}

.titleSobmedida + .mat-card-content,
.titleTotal + .mat-card-content {
    .mat-form-field-wrapper {
        padding-bottom: 0;
    }
}

#valorSobMed {
    .mat-form-field-appearance-outline .mat-form-field-outline-start,
    .mat-form-field-appearance-outline .mat-form-field-outline-end,
    .mat-form-field-appearance-outline .mat-form-field-outline-gap {
        border: none !important;
    }

    .mat-form-field-infix {
        padding: 0 !important;
        border-top: 0 !important;
    }

    .mat-input-element {
        text-align: center;
        color: #424242;
        font-size: 18px;
        font-weight: 500;
    }
}

.contact-social-mobile {
    .icons-mobile {
        width: 35px;
        height: 35px;
        line-height: 24px;
        background: #1a1a1a;
        padding: 5px;
        border-radius: 5px;
    }

    .icons-mobile.icon-youtube {
        width: 33px;
        height: 33px;
    }
}

#bannersHome {
    // height: 25vw;
    .slideBanner img {
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
        display: block;
    }
}

.backLoading {
    position: absolute;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.backLoading {
    position: absolute;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.backLoading-maioricasa {
    background: rgb(255 255 255 / 40%);
}

.backLoadingHome {
    position: absolute;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: #000000;
    top: 0;
    left: 0;
}

.backLoading-home {
    background: #000000;
}

#depoimentosHome {
    margin-top: 4vw;
    margin-bottom: 4vw;

    .title {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .item-depoimento {
        .ratings {
            color: #fbc02d;
        }

        .texto-depoimento {
            font-size: 14px;
            font-style: italic;
        }

        .autor-depoimento {
            width: 100%;
            display: block;
            font-weight: bold;
            font-size: 12px;
        }
    }
}

.input-car {
    width: 40px;
    text-align: center;
    border: none;
    font-weight: 700;
}

.input-car::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.input-car {
    -moz-appearance: textfield;
    appearance: textfield;
}

.remove-seta {
    -moz-appearance: textfield;
    appearance: textfield;
}

.remove-seta::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* MENSAGENS / AVISOS */

.cdk-global-overlay-wrapper .cdk-overlay-pane {
    width: 100%;
    left: 0;
}

.app.maioricasa .mat-snack-bar-container.success {
    background: #006805 !important;
    color: #fff;
}

.app.maioricasa
    .mat-expansion-panel:not(.mat-expanded)
    .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
    color: inherit;
    background: inherit !important;
    border: none;
}

.app.maioricasa .mat-stroked-button {
    color: inherit;
    background: inherit !important;
    border: none;
}

.app.maioricasa .search-dropdown.mat-menu-panel {
    background: #fafafa !important;
}

.mat-snack-bar-container {
    color: #6a4e3e;
    background: #ecdccd !important;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
        0px 6px 10px 0px rgba(0, 0, 0, 0.14),
        0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-snack-bar-container {
    text-transform: uppercase;
    margin: 0 auto !important;
    border-radius: 0 !important;
    padding: 15px 15px !important;
    max-width: 40% !important;
    min-width: 40% !important;
    border-radius: 0.5rem !important;
}

.mat-simple-snackbar {
    font-size: 15px !important;
}

.colQtde {
    bdi {
        display: flex;
        align-items: center;
    }

    .input-car {
        width: 40px;
        text-align: center;
        /* border: none; */
        width: 60px;
        padding: 5px 0;
        display: inline-block;
    }

    #btnsQtde {
        display: inline-block;
        width: 30px;

        /* background: aquamarine; */
        .mat-icon-button {
            display: inline-block;
            width: 30px;
            height: 40px;
            line-height: 40px;
        }
    }
}

/* Sob Medida */

.cardTamanhos .mat-card-content,
.cardAcabamento .mat-card-content {
    position: relative;
    margin-bottom: 0 !important;
}

.cardTamanhos,
.cardAcabamento {
    .mat-hint {
        position: relative;
        top: -7px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        display: block;
        font-size: 12px;
        color: red !important;
    }
}

.cardAcabamento .mat-hint {
    width: 85%;
}

@media (max-width: 1130px) {
    .cardAcabamento .mat-hint {
        width: 95%;
    }
}

@media (max-width: 1100px) {
    .cardTamanhos .mat-hint,
    .cardAcabamento .mat-hint {
        font-size: 10.5px;
    }

    .cardTamanhos .mat-hint {
        width: 90%;
    }
}

@media (max-width: 959px) {
    .cardTamanhos .mat-hint,
    .cardAcabamento .mat-hint {
        font-size: 12px;
    }
}

@media (max-width: 450px) {
    .cardTamanhos .mat-hint,
    .cardAcabamento .mat-hint {
        font-size: 11px;
    }
}

.app.maioricasa .mat-chip.mat-standard-chip::after {
    background: none !important;
}

.app.maioricasa .mat-snack-bar-container span {
    margin: 0 auto;
}

.mt-05 {
    margin-top: 5px !important;
}

.min-200 {
    min-width: 200px;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-justify {
    text-align: justify;
}

.align-center {
    text-align: center;
}

.margin-auto {
    margin: auto;
}

/*ACCORDION*/

.example-headers-align .mat-expansion-panel-header-title,
.example-headers-align .mat-expansion-panel-header-description {
    flex-basis: 0;
    background-color: #fafafa;
}

.example-headers-align .mat-expansion-panel-header-description {
    justify-content: space-between;
    align-items: center;
}

.example-headers-align .mat-form-field + .mat-form-field {
    margin-left: 8px;
}

// .mat-drawer {
//     background-color: #fafafa !important;
// }

.mat-drawer-content {
    overflow-x: hidden !important;
}

.fonte-abel {
    // font-family: "Montserrat", cursive !important;
    font-family: "uni-neue-light", cursive !important;
}

.fonte-w-bolder {
    font-weight: bolder;
}

/* Master Styles */

.container {
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
}

#mat-card-color {
    color: rgba(0, 0, 0, 0.87);
    background: rgb(247, 247, 247);
    // background-color: #fafafa;
}

.mat-drawer-container {
    background-color: #efefef;
}

.mat-sidenav-content,
.mat-sidenav-container,
.mat-drawer-content {
    transform: none !important;
}

.mat-dialog-container {
    padding: 0 !important;
}

.mat-expansion-panel-body {
    padding: 0px 24px 16px !important;
    overflow-y: scroll;
}

.box {
    border-radius: 0;

    .box-header {
        height: 180px;

        .error {
            font-size: 55px;
            // margin-bottom: 12px;
        }
    }

    .box-content {
        position: relative;
        height: 180px;

        .box-content-inner {
            position: absolute;
            top: -34px;
            left: 34px;
            right: 34px;
            height: 180px;
        }

        .box-content-header {
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 500;

            &.server-error {
                margin-bottom: 36px;
            }
        }

        .box-text {
            margin-bottom: 10px;
            text-align: center;

            &::last-child {
                margin-bottom: 15px;
            }
        }

        .box-footer {
            position: relative;
            bottom: 16px;

            button {
                min-width: 70px;
                margin: 0 2px;
            }
        }
    }
}

.input-onde-comprar .mat-form-field-infix {
    padding: 25px 0px 8px;
    border-top: 0.84375em solid transparent;
}

.input-onde-comprar .mat-form-field-infix label {
    font-size: 25px;
    font-weight: bolder;
}

.cdk-overlay-connected-position-bounding-box {
    // top: 8px !important;
    top: 25px !important;
    left: 21px;
}

.customize + * .mat-menu-panel {
    background: #b8aa9ea1;
}

//MUDANÇAS DE BORDAS PARA O INPUT FIELD ANGULAR MATERIL

.mat-form-field-appearance-outline .mat-form-field-outline {
    color: #6a4e3e !important;
}

.mat-form-field-appearance-outline:not(.mat-form-field-disabled)
    .mat-form-field-flex:hover
    .mat-form-field-outline {
    opacity: 1 !important;
}

// BORDA NO FECHAR E NO SIDENAV

.mat-button-focus-overlay {
    background-color: transparent !important;
}

//

::ng-deep {
    .mat-form-field-appearance-outline .mat-form-field-outline {
        color: #ecdccd;
    }

    /* Font color */
    input.mat-input-element {
        color: #ecdccd;
    }

    mat-form-field {
        .mat-hint,
        input,
        ::placeholder,
        .mat-form-field-label {
            font-family: "public-sans-light" !important;
        }
    }
}

::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: #fff;
}
::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex {
    border-radius: 4px;
    padding: 0.75em 0.75em 0 0.75em;
    border: 1px solid #f94747;
}

::ng-deep button.mat-button {
    outline: none;
}

::ng-deep button.mat-button .mat-focus-indicator {
    display: none;
}

::ng-deep
    button.mat-button
    mat-focus-indicator
    sidenav-toggle
    mat-button
    mat-button-base {
    display: none;
}

:host ::ng-deep .cdk-program-focused {
    outline: none;
}

.mt-4rem {
    margin-top: 4rem;
}

.mat-drawer-backdrop.mat-drawer-shown,
.cdk-overlay-dark-backdrop {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
}

.input .mat-form-field-flex {
    height: 44px !important;
    align-items: center;
}

.dialog-container-arredondado .mat-dialog-container {
    border-radius: 14px;
}

.cart-icons .mat-icon-button {
    padding: 0;
    min-width: 0;
    width: 30px;
    height: 40px;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%;
}

.white-border {
    mat-form-field {
        &.mat-focused .mat-form-field-outline {
            color: white !important;
        }

        &.mat-form-field-appearance-outline .mat-form-field-outline {
            color: white !important;
        }
        &.mat-select-value-text .mat-select-value {
            color: white !important;
        }
    }
    .custom-select-value .mat-select-value,
    .mat-form-field-label,
    .mat-select-arrow {
        color: white !important;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #6a4e3e !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
    .mat-radio-ripple
    .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #6a4e3e !important;
}

.mat-checkbox-layout .mat-checkbox-label {
    color: #6a4e3e;
}

.mat-checkbox-frame {
    border-color: #684e3c;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
    background-color: #6a4e3e;
}

.checkbox-agree .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #6a4e3e !important;
}
