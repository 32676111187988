/* You can add global styles to this file, and also import other style files */

// @import url('https://unpkg.com/bootstrap@3.3.7/dist/css/bootstrap.min.css');
// @import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;1,300&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;700&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap'); // Comfortaa fale conosco
// @import url('https://fonts.googleapis.com/css2?family=Abel:wght@300&display=swap'); // Abel fale conosco
// @import url('https://fonts.googleapis.com/css?family=Abel');
// @import url('https://fonts.googleapis.com/css?family=Centaur');
// @import url("https://fonts.googleapis.com/css2?family=Cinzel+Decorative&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Public+Sans&display=swap');
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~swiper/dist/css/swiper.min.css";
@import "app/theme/styles/base";
// @import "app/theme/styles/theme";
// @import "app/theme/styles/rtl";
// @font-face {
//   font-family: "centaur";
//   src: url("assets/fonts/centaur-normal.ttf");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "abel";
//   src: url("assets/fonts/abel.ttf");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Artifakt";
//   src: url("assets/fonts/artifakt-element-regular.ttf");
// }

// @font-face {
//     font-family: "cinzel-decorative-regular";
//     src: url("assets/fonts/CinzelDecorative-Regular.ttf");
// }
// @font-face {
//     font-family: "caviar-dreams";
//     src: url("assets/fonts/CaviarDreams.ttf");
// }

// @font-face {
//     font-family: "caviar-dreams-bold";
//     src: url("assets/fonts/CaviarDreams_Bold.ttf");
// }

// @font-face {
//     font-family: "gothic";
//     src: url("assets/fonts/gothic.TTF");
// }

// @font-face {
//   font-family: "public-sans-regular";
//   src: url("assets/fonts/public-sans/PublicSans-Regular.ttf");
// }

// @font-face {
//   font-family: "caviar-dreams";
//   src: url("assets/fonts/fontes-antigas/CaviarDreams.ttf");
// }

// @font-face {
//   font-family: "caviar-dreams-bold";
//   src: url("assets/fonts/fontes-antigas/CaviarDreams_Bold.ttf");
// }

// @font-face {
//   font-family: "gothic";
//   src: url("assets/fonts/gothic.TTF");
// }

// @font-face {
//   font-family: "public-sans-regular";
//   src: url("assets/fonts/public-sans/PublicSans-Regular.otf");
// }

// @font-face {
//   font-family: "public-sans-light";
//   src: url("assets/fonts/puclic-sans/PublicSans-Light.otf");
// }

// @font-face {
//   font-family: "public-sans-thin";
//   src: url("assets/fonts/puclic-sans/PublicSans-Thin.otf");
// }

@font-face {
  font-family: "uni-neue-regular";
  src: url("assets/fonts/uni-neue/UniNeueRegular.otf");
}

@font-face {
  font-family: "uni-neue-light";
  src: url("assets/fonts/uni-neue/UniNeueLight.otf");
}


@font-face {
    font-family: "uni-neue-regular";
    src: url("assets/fonts/uni-neue/UniNeueRegular.otf");
}



@font-face {
  font-family: "public-sans-light";
  src: url("assets/fonts/public-sans/PublicSans-Light.otf");
}

@font-face {
  font-family: "public-sans-regular";
  src: url("assets/fonts/public-sans/PublicSans-Regular.otf");
}

@font-face {
  font-family: "public-sans-bold";
  src: url("assets/fonts/public-sans/PublicSans-Bold.otf");
}